import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Tab, Tabs } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import { format } from 'date-fns';

import CampaignFacebook from '@/components/campaigns-page/CampaignFacebook';
import CampaignInstagram from '@/components/campaigns-page/CampaignInstagram';
import CampaignSpotify from '@/components/campaigns-page/CampaignSpotify';
import CampaignSocialMediaMetaReport from '@/components/campaigns-page/CampaignsSocialMediaMetaReport';
import CampaignTikTok from '@/components/campaigns-page/CampaignTikTok';
import CampaignYoutube from '@/components/campaigns-page/CampaignYoutube';
import CampaignSummaryCard from '@/components/cards/CampaignSummaryCard';
import Loading from '@/components/utility/Loading';
import UserHeader from '@/components/utility/navigation/UserHeader';
import FacebookPostTemplate from '@/components/utility/social-media-templates/FacebookPostTemplate';
import InstaPostTemplate from '@/components/utility/social-media-templates/InstaPostTemplate';
import InstaStoryTemplate from '@/components/utility/social-media-templates/InstaStoryTemplate';
import CampaignStatus from '@/components/utility/statuses/CampaignStatus';
import { genderMap } from '@/constants/Advert';
import { CountryNameConverter } from '@/formatters/CountryNameConverter';
import { capitalizeFirstLetter } from '@/formatters/TextTransformers';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useMetaAd from '@/hooks/meta/useMetaAd';
import useMetaCreative from '@/hooks/meta/useMetaCreative';
import { MetaAdVideoDataThumbnailsDataModel } from '@/models/Meta';
import FacebookAPI from '@/network/FacebookAPI';
import MetaAPI from '@/network/MetaAPI';
import { handleApiError } from '@/utility/api';

const CampaignMetaPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { accountId, account } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const [value, setValue] = useState<number>(0);
  const [tabValue, setTabValue] = useState<number>(0);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isUpdateCampaignDisabled, setIsUpdateCampaignDisabled] = useState<boolean>(false);
  const [adVideoThumbnail, setAdVideoThumbnail] = useState<string>();

  const advertId = useMemo(() => params.slug, [params]);

  const { metaAd, metaAdIsLoading, refetchMetaAd } = useMetaAd({ advertId });
  const { metaCreative, metaCreativeIsLoading } = useMetaCreative({ advertId: metaAd?.id });

  const campaignSummary = useMemo(() => {
    if (!metaAd) return [];

    return [
      {
        name: 'CAMPAIGNS-PAGE.GOAL',
        value: metaAd.summary.goal ? metaAd.summary.goal.replace('-', ' ') : 'N/A',
      },
      {
        name: 'CAMPAIGNS-PAGE.START-DATE',
        value: `${format(new Date(metaAd.summary.startTime), 'dd/MM/yy')} at ${format(new Date(metaAd.summary.startTime), 'HH:mm b')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.END-DATE',
        value: `${format(new Date(metaAd.summary.endTime), 'dd/MM/yy')} at ${format(new Date(metaAd.summary.endTime), 'HH:mm b')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.BUDGET',
        value: metaAd.summary.budget
          ? `${getSymbolFromCurrency(metaAd.summary.currency?.code ?? 'GB')}${metaAd.summary.budget / (metaAd.summary.currency?.offset ?? 0)}`
          : 'N/A',
      },
      {
        name: 'CAMPAIGNS-PAGE.AUDIENCE',
        value: `${t(genderMap[metaAd.summary.genders[0] ?? 0])}, ${metaAd.summary.minAge}-${metaAd.summary.maxAge}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.LOCATIONS',
        value: Object.values(metaAd.summary.locationTargeting)
          .flatMap((value) =>
            Array.isArray(value)
              ? value.map((item) => (typeof item === 'string' ? CountryNameConverter(item) : item.name))
              : value
          )
          .join(', '),
      },
      {
        name: 'CAMPAIGNS-PAGE.INTERESTS',
        value: metaAd.summary.audiences.map((audience) => audience.name).join(', '),
      },
      {
        name: 'CAMPAIGNS-PAGE.PLATFORM',
        value: metaAd.summary.platforms.map((platform: string) => capitalizeFirstLetter(platform)).join(', '),
      },
    ];
  }, [metaAd, t]);

  const updateMetaCampaignStatus = useCallback(
    async (status: string) => {
      try {
        if (!accountId || !metaAd || !account) return;

        await MetaAPI.updateMetaCampaign({
          accountId,
          advertId: metaAd.id,
          data: {
            status,
            facebookAccessToken: account.accessTokens?.filter((item) => item.platform === 'meta')[0].accessToken,
          },
        });
        await refetchMetaAd();
      } catch (error: unknown) {
        if (metaAd) {
          await refetchMetaAd();
        }
        handleApiError({ error, dispatchSnackbar });
      } finally {
        setDialogOpen(false);
        setIsUpdateCampaignDisabled(false);
      }
    },
    [account, accountId, dispatchSnackbar, metaAd, refetchMetaAd]
  );

  useEffect(() => {
    if (metaAd) {
      if (metaAd?.summary.platforms.includes('facebook')) {
        setTabValue(0);
      } else {
        setTabValue(2);
      }
    }
  }, [metaAd]);

  useEffect(() => {
    if (metaCreative?.video_id && account?.accessTokens?.filter((item) => item.platform === 'meta')[0].accessToken) {
      FacebookAPI.getVideoDetailsById({
        videoId: metaCreative?.video_id,
        accessToken: account?.accessTokens?.filter((item) => item.platform === 'meta')[0].accessToken,
      }).then((response) => {
        setAdVideoThumbnail(
          response.data.thumbnails.data.filter((item: MetaAdVideoDataThumbnailsDataModel) => item.is_preferred)[0].uri
        );
      });
    }
  }, [account?.accessTokens, metaCreative]);

  return (
    <div data-testid="campaigns-playlist-pitch-page" className="page-content">
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>{t('CAMPAIGNS-PAGE.EDIT-CAMPAIGN')}</DialogTitle>
        <DialogContent>{t('CAMPAIGNS-PAGE.EDIT-CAMPAIGN-DESCRIPTION')}</DialogContent>
        <DialogActions>
          <Button
            disabled={isUpdateCampaignDisabled || metaAd?.status === 'active'}
            onClick={() => {
              setIsUpdateCampaignDisabled(true);
              updateMetaCampaignStatus('active');
            }}
          >
            {t('COMMON.ACTIVATE')}
          </Button>
          <Button
            disabled={isUpdateCampaignDisabled || metaAd?.status === 'paused'}
            onClick={() => {
              setIsUpdateCampaignDisabled(true);
              updateMetaCampaignStatus('paused');
            }}
          >
            {t('COMMON.PAUSE')}
          </Button>
          <Button
            disabled={isUpdateCampaignDisabled || metaAd?.status === 'archived'}
            onClick={() => {
              setIsUpdateCampaignDisabled(true);
              updateMetaCampaignStatus('archived');
            }}
          >
            {t('COMMON.ARCHIVE')}
          </Button>
        </DialogActions>
      </Dialog>
      <UserHeader title="PAGE-TITLES.CAMPAIGNS" />
      <div className="d-flex jc-space-between mt16">
        <div className="d-flex jc-start">
          <Button
            className="ml0 btn-back"
            onClick={() => {
              navigate('/campaigns', { state: 0 });
            }}
          >
            <Icon>chevron_left</Icon>
            <p className="pt2 pl4">{t('COMMON.BACK')}</p>
          </Button>
        </div>
        <div>
          <Button
            className="mr0 btn-back"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <Icon>more_horiz</Icon>
          </Button>
        </div>
      </div>
      {metaAdIsLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {!metaAdIsLoading && (
        <div>
          <div className="card mt16 playlist-pitch-title-card">
            {!metaCreativeIsLoading && (
              <div
                className="pos-abs"
                style={{
                  backgroundImage: `
                    url(${metaCreative?.image_url || adVideoThumbnail || '/images/logos/social-media-icon.svg'})
                  `,
                  borderRadius: '12px 12px 12px 12px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: '-50%',
                  height: '180px',
                  width: '100%',
                }}
              ></div>
            )}
            <div className="d-flex inner-titles social-media">
              <img src={metaCreative?.image_url || adVideoThumbnail || '/images/logos/social-media-icon.svg'} alt="" />
              <div className="pl16 d-flex flex-d-col gap8 mt-auto mb-auto">
                <h3>{metaAd?.name}</h3>
                <p className="text-faded small">
                  {`Started on ${metaAd?.summary?.startTime && format(new Date(metaAd?.summary?.startTime), 'dd/MM/yy')}`}
                </p>
                <CampaignStatus status={metaAd?.status || ''} />
              </div>
            </div>
          </div>
          <div className="mt20 card">
            <Box
              sx={{
                width: '100%',
                marginBottom: '8px',
              }}
            >
              <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
                <Tab label={t('PLAYLISTING.SUMMARY')} />
                <Tab label={t('COMMON.PERFORMANCE')} />
              </Tabs>
            </Box>
            {value === 0 && (
              <div className="d-flex gap20 flex-wrap">
                <div className="flex-w50p w100p-lg-down">
                  {campaignSummary && <CampaignSummaryCard summary={campaignSummary} />}
                </div>
                <div className="flex-w50p w100p-lg-down">
                  {metaAd && (metaCreative?.image_url || adVideoThumbnail) && (
                    <div className="ml-auto mr-auto text-center">
                      <h3 className="mt20-lg-down ">Preview your ad</h3>
                      <p className="text-faded mt10 mb20">See what your ad looks like on different platforms</p>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: 'divider',
                          width: '100%',

                          marginBottom: '8px',
                        }}
                      >
                        <Tabs
                          value={tabValue}
                          onChange={(_, value: number) => setTabValue(value)}
                          aria-label="basic tabs example"
                        >
                          {metaAd?.summary?.platforms?.includes('facebook') && (
                            <Tab
                              value={0}
                              label={
                                <div className="d-flex gap8">
                                  <img
                                    className="summary-preview-icon"
                                    src="/images/logos/facebook-logo-white.svg"
                                    alt=""
                                  />
                                  <span>{t('BRAND.FACEBOOK')}</span>
                                </div>
                              }
                              data-testid="insights-overview"
                            />
                          )}

                          {metaAd?.summary?.platforms?.includes('instagram') && (
                            <Tab
                              value={1}
                              label={
                                <div className="d-flex gap8">
                                  <img
                                    className="summary-preview-icon"
                                    src="/images/logos/instagram-logo-white.svg"
                                    alt=""
                                  />
                                  <span>{t('COMMON.POST')}</span>
                                </div>
                              }
                              data-testid="insights-spotify"
                            />
                          )}

                          {metaAd?.summary?.platforms?.includes('instagram') && (
                            <Tab
                              value={2}
                              label={
                                <div className="d-flex gap8">
                                  <img
                                    className="summary-preview-icon"
                                    src="/images/logos/instagram-logo-white.svg"
                                    alt=""
                                  />
                                  <span>{t('COMMON.STORY')}</span>
                                </div>
                              }
                              data-testid="insights-youtube"
                            />
                          )}
                        </Tabs>
                      </Box>
                      {tabValue === 0 && metaCreative && (
                        <FacebookPostTemplate
                          mediaUrl={adVideoThumbnail || metaCreative?.image_url}
                          mediaType="image"
                          headline={metaAd.summary.content.linkHeadline || ''}
                          description={metaAd.summary.content.linkDescription || ''}
                          text={metaAd.summary.content.primaryText || ''}
                          link={metaAd.summary.content.link || ''}
                          ctaText={'Listen Now'}
                        />
                      )}
                      {tabValue === 1 && metaCreative && (
                        <InstaPostTemplate
                          pageName={'your_page_name'}
                          mediaUrl={adVideoThumbnail || metaCreative?.image_url}
                          mediaType="image"
                          caption={metaAd.summary.content.primaryText || ''}
                          ctaText={metaCreative?.ctaText || 'Listen Now'}
                        />
                      )}
                      {tabValue === 2 && metaCreative && (
                        <InstaStoryTemplate
                          mediaUrl={adVideoThumbnail || metaCreative?.image_url}
                          mediaType="image"
                          caption={metaAd.summary.content.primaryText || ''}
                          ctaText={metaCreative?.ctaText || 'Listen Now'}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {value === 1 && (
              <div className="w100p">
                {metaAd && <CampaignSocialMediaMetaReport campaignId={metaAd?.id} />}
                {metaAd?.summary.goal === 'more-streams' && <CampaignSpotify startTime={metaAd.summary.startTime} />}
                {metaAd?.summary.goal === 'spotify-followers' && (
                  <CampaignSpotify startTime={metaAd.summary.startTime} />
                )}
                {metaAd?.summary.goal === 'instagram-followers' && (
                  <CampaignInstagram startTime={metaAd.summary.startTime} />
                )}
                {metaAd?.summary.goal === 'facebook-likes' && <CampaignFacebook startTime={metaAd.summary.startTime} />}
                {metaAd?.summary.goal === 'tiktok-followers' && <CampaignTikTok startTime={metaAd.summary.startTime} />}
                {metaAd?.summary.goal === 'youtube-views' && <CampaignYoutube startTime={metaAd.summary.startTime} />}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignMetaPage;
